import { FC } from 'react';
import { FooterT } from '@brainysoft/lk-components';
import { FooterView } from '../../../components/Footer/FooterView';

const footer: FooterT = {
  disclaimer: [
    'ООО МКК «ФосФин» (ИНН 3702259225, ОГРН 1213700007135, КПП 370201001, зарегистрировано в государственном реестре МФО 30.06.2021 № 2103024009699, является членом Саморегулируемой организация Союз микрофинансовых организаций «Микрофинансирование и Развитие» (СРО «МиР») с 26.07.2021, рег. номер 37 001208).',
  ],
  menu: [
    [
      {
        url: 'https://fosfinance.ru/docsforlk/doc.html',
        target: '_blank',
        text: 'Документы',
      },
    ],
  ],
  contacts: [
    [
      {
        label: 'Телефон:',
        text: '+7 (800) 500 34 24',
      },
      {
        label: 'Электронная почта:',
        text: 'ooomkkfosfin@yandex.ru',
      },
    ],
    [
      {
        label: 'Адрес',
        text: 'Россия, 153012, г. Иваново, ул. Советская, д. 32, помещ. 30',
      },
      {
        label: 'График работы:',
        text: 'с 8-00 до 19-00 по Московскому времени. Без выходных',
      },
    ],
  ],
};

export const Footer: FC = () => {
  return <FooterView footer={footer} />;
};
