import * as React from 'react';
import { ResultIcon } from '../../ResultIcon';
import { Button } from '../../Button';
import { IActiveCreditStatus } from '../ActiveCreditSelector';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { ActiveCreditStatusLoading } from '../ActiveCreditStatusLoading';
import { creditService, CreditStatusType, ResultType } from '@brainysoft/lk-components';
import { useTranslation } from '../../../utils/useTranslation';
import moment from 'moment';
import { useGoogleAnalytics } from 'apps/lk-next/src/integrations/useGoogleAnalytics';
import { useYandexMetrika } from 'apps/lk-next/src/integrations/useYandexMetrika';

export const Denied: React.FC<IActiveCreditStatus> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { credit } = props;
  const { t } = useTranslation();

  const [denied, setDenied] = React.useState<{ expirationDate: number | null; link: string }>({
    expirationDate: null,
    link: '',
  });
  const [isDeniedLoading, setIsDeniedLoading] = React.useState(true);

  React.useEffect(() => {
    let canceled = false;
    const fetchDenied = async () => {
      setIsDeniedLoading(true);
      const res = await creditService.denyText(credit?.uuid);
      if (res && !canceled) setDenied(res);
      if (!canceled) setIsDeniedLoading(false);
    };
    if (credit.status === CreditStatusType.DENIED) {
      fetchDenied();
    }
    return () => {
      canceled = true;
    };
  }, [credit.status]);

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const iconSize = 60;

  const text = denied.expirationDate
    ? t('deny:headerWithDate', { date: moment(denied.expirationDate).format('DD.MM.YYYY г.') })
    : t('deny:header');

  const partnersText = denied.link ? t('deny:partnersText') : '';

  return (
    <React.Fragment>
      {isDeniedLoading && <ActiveCreditStatusLoading />}
      {!isDeniedLoading && (
        <div className={`${classes.wrapper} inWidget`}>
          <h2 className={classes.deniedHeader}>{text}</h2>
          <ResultIcon result={ResultType.FAIL} size={iconSize} />
          {!denied?.link && (
            <div className={`${classes.deniedDescription} mt1`}>{t('active:statuses.denied.tryAgainLater')}.</div>
          )}
          {denied?.link && (
            <React.Fragment>
              <div className={`${classes.deniedDescription} mt1`}>{partnersText}</div>
              <Button
                size={'large'}
                type={'primary'}
                className={classes.homeButton}
                onClick={() => {
                  if (denied?.link) {
                    ga.send('monetization');
                    ym.send('monetization');
                    window.open(denied?.link, '_blank');
                  }
                }}
              >
                {t('active:statuses.denied.go')}
              </Button>
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
