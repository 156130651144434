import * as React from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { FormikProps } from 'formik';
import { Collapse, Panel } from '../../../../components/Collapse';
import { get, isEqual } from 'lodash';
import { Col, Row } from 'antd';
import { Button } from '../../../../components/Button';
import { MaskedInput } from '../MaskedInput';
import { BS_SMS_CODE_PATTERN } from '../MaskedInput/masks';
import { OtpChallengeType } from '@brainysoft/lk-components';
import { Checkbox } from '../CheckBox';
import { Input } from '../Input';
import useDebouncedCallback from '../../../../utils/useDebouncedCallback';
import { parseTimeout, useChallenge } from '../../../../utils/useChallenge';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useTranslation } from '../../../../utils/useTranslation';
import { ButtonType } from 'antd/lib/button/button';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import ReCAPTCHA from 'react-google-recaptcha';
import { otpService, creditService, cacheService as Storage } from '@brainysoft/lk-components';

interface IChallengeApproval {
  formik: FormikProps<any>;
  idField: string;
  approveField: string;
  otpChallengeType: OtpChallengeType;
  description?: string;
  horizontal?: boolean;
  challengeButtonSize?: SizeType;
  challengeButtonBlock?: boolean;
  proofButtonSize?: SizeType;
  proofButtonBlock?: boolean;
  proofButtonType?: ButtonType;
  proofButtonDisabled?: boolean;
  onApprove?: (result) => Promise<any>;
  challengePayload?: Record<string, unknown>;
  phoneValidator?: (mobilePhone: string, required?: boolean) => string | undefined;
  mobilePhone: string;
  canSendChallenge?: boolean;
  onBeforeGetChallenge?: (mobilePhone: string) => Promise<boolean>;
  isPhoneCodeRequired?: boolean;
  debounceDelayMs?: number;
  shouldValidateOnChange?: boolean;
}

export const _ChallengeApproval = (props: IChallengeApproval) => {
  const { t, keyExists } = useTranslation();
  const classes = useThemeStyles('Form/PhoneInputApprove');
  const {
    otpChallengeType,
    formik,
    idField,
    horizontal,
    challengeButtonSize,
    challengeButtonBlock,
    proofButtonSize,
    proofButtonBlock,
    proofButtonType,
    proofButtonDisabled,
    challengePayload,
    mobilePhone,
    approveField,
    phoneValidator,
    canSendChallenge = true,
    onBeforeGetChallenge,
    isPhoneCodeRequired = true,
    debounceDelayMs = 0,
    shouldValidateOnChange = false,
  } = props;

  const [isCodeFieldLoading, setIsCodeFieldLoading] = React.useState(false);
  const [phoneProofStatus, setPhoneProofStatus] = React.useState<string | undefined>(undefined);

  const [validPhone, setValidPhone] = React.useState<string | undefined>();
  const [recaptchaProof, setRecaptchaProof] = React.useState<string | undefined>();
  const [isSendingRequests, setIsSendingRequests] = React.useState(false);
  //
  // const validatePhone = (mobilePhone) => mobilePhone?.length >= 11;

  const phoneValidatorFn: (mobilePhone: string) => boolean = (mobilePhone) => {
    if (typeof phoneValidator === 'function') {
      return !!phoneValidator(mobilePhone);
    }
    return !mobilePhone || mobilePhone?.length < 11;
  };

  React.useEffect(() => {
    setPhoneProofStatus(undefined);
    formik.setFieldValue(approveField, false);
    formik.setFieldValue('phoneCode', undefined);
    if (!phoneValidatorFn(mobilePhone)) {
      setValidPhone(canSendChallenge ? mobilePhone : undefined);
    }
  }, [mobilePhone, canSendChallenge]);

  const {
    getChallenge,
    isDisabled,
    isSending,
    isSent,
    timeout,
    channel,
    isHandshakeLoading,
    showRecaptcha,
    siteKey,
    authId,
  } = useChallenge(otpChallengeType, validPhone);

  const onApprove = async (code) => {
    await setIsCodeFieldLoading(true);
    try {
      if (!authId) throw new Error('Undefined authId');
      let res, uuid, payloadData;
      switch (otpChallengeType) {
        case OtpChallengeType.OFERTA:
          if (!challengePayload?.uuid) throw new Error('Undefined uuid');
          uuid = challengePayload?.uuid;
          payloadData = { ...challengePayload };
          delete payloadData.uuid;
          res = await creditService.signOfferChallengeAnswer(uuid as string, code, payloadData);
          break;
        default:
          res = await otpService.submitChallenge(otpChallengeType, authId, code);
      }
      if (res) {
        setPhoneProofStatus('success');
        formik.setFieldValue(approveField, true);
        formik.setFieldValue(idField, authId);
        setIsCodeFieldLoading(false);
        await Storage.removeItem(`challenge:${otpChallengeType}:challengeId`);
        if (typeof props.onApprove === 'function') {
          await props.onApprove(res);
        }
      } else {
        console.log('error', res);
        formik.setFieldError('phoneCode', 'wrongCode');
        setIsCodeFieldLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      formik.setFieldError('phoneCode', 'wrongCode');
      setIsCodeFieldLoading(false);
    }
  };

  const onApproveDebounce = useDebouncedCallback((code) => {
    onApprove(code);
  }, debounceDelayMs);

  const handlerCodeChange = (e) => {
    if (debounceDelayMs === 0) return;
    const value = e.target.value;
    if (value.length === BS_SMS_CODE_PATTERN.length) onApproveDebounce(value);
    else onApproveDebounce.cancel();
  };

  const timeLeft = parseTimeout(timeout);
  const showTimer = !!(timeLeft.minutes || timeLeft.seconds);

  if (!validPhone) return null;

  const onRecaptchaProof = (value: string) => {
    console.log(value);
    setRecaptchaProof(value);
  };

  const challengeDisclaimer = keyExists(`otp:${otpChallengeType}.${channel}.description`)
    ? t(`otp:${otpChallengeType}.${channel}.description`)
    : t(`otp:allChallenges.${channel}.description`);
  const challengeButtonText = keyExists(`otp:${otpChallengeType}.${channel}.button`)
    ? t(`otp:${otpChallengeType}.${channel}.button`)
    : t(`otp:allChallenges.${channel}.button`);

  return (
    <div className={classes.approveWrapper}>
      {isHandshakeLoading && (
        <div className={classes.spinnerWrapper}>
          <LoadingSpinner size={8} margin={4} />
        </div>
      )}
      {validPhone && (
        <React.Fragment>
          <Checkbox name={approveField} hidden initialValue={false} />
          <Input name={idField} hidden initialValue={''} />
          <Row gutter={24}>
            {showRecaptcha && siteKey && (
              <Col xs={24} sm={24}>
                <div className={classes.captchaWrapper}>
                  <div className={classes.disclaimer}>{t('otp:disclaimers.captcha')}</div>
                  <ReCAPTCHA sitekey={siteKey} onChange={onRecaptchaProof} hl={'ru'} />
                </div>
              </Col>
            )}
            <Col xs={24} sm={horizontal ? 12 : 24}>
              <div className={classes.approveContentWrapper}>
                {!!channel && <div className={classes.disclaimer}>{challengeDisclaimer}</div>}
                <div className={classes.disclaimer}>
                  {showTimer && (
                    <span>
                      {t('otp:disclaimers.approval', {
                        min: timeLeft.minutes
                          ? timeLeft.minutes < 10
                            ? `0${timeLeft.minutes}`
                            : timeLeft.minutes
                          : '00',
                        sec: timeLeft.seconds
                          ? timeLeft.seconds < 10
                            ? `0${timeLeft.seconds}`
                            : timeLeft.seconds
                          : '00',
                      })}
                    </span>
                  )}
                </div>
                {!!channel && (
                  <Button
                    className={classes.sendButton}
                    size={challengeButtonSize ?? 'small'}
                    disabled={isSendingRequests || isDisabled || !validPhone || (showRecaptcha && !recaptchaProof)}
                    loading={isSending}
                    block={!!challengeButtonBlock}
                    onClick={async (e) => {
                      e.preventDefault();
                      setIsSendingRequests(true);
                      let allowChallenge = true;
                      if (typeof onBeforeGetChallenge === 'function')
                        allowChallenge = await onBeforeGetChallenge(validPhone);
                      if (allowChallenge) await getChallenge({ ...challengePayload, hypnotoadCode: recaptchaProof });
                      setIsSendingRequests(false);
                    }}
                  >
                    {challengeButtonText}
                  </Button>
                )}
              </div>
            </Col>
            <Col xs={24} sm={horizontal ? 12 : 24}>
              <Collapse
                className={`simple headerless paddingless ${classes.collapseWrapper} ${horizontal ? 'horizontal' : ''}`}
                bordered={false}
                activeKey={isSent ? 1 : undefined}
              >
                <Panel showArrow={false} header='' forceRender={true} key='1'>
                  <div className={classes.inputWrapper}>
                    <MaskedInput
                      name='phoneCode'
                      mask='pattern'
                      pattern={BS_SMS_CODE_PATTERN}
                      required={isPhoneCodeRequired}
                      loading={isCodeFieldLoading}
                      initialValue={null}
                      validateStatus={phoneProofStatus}
                      onChange={handlerCodeChange}
                      shouldValidateOnChange={shouldValidateOnChange}
                    />
                    {debounceDelayMs === 0 && (
                      <Button
                        size={proofButtonSize ?? 'middle'}
                        type={proofButtonType}
                        block={!!proofButtonBlock}
                        loading={isCodeFieldLoading}
                        disabled={isCodeFieldLoading || proofButtonDisabled === true}
                        onClick={() => {
                          onApprove(get(formik, 'values.phoneCode'));
                        }}
                      >
                        {t(`otp:actions.challengeApprove`)}
                      </Button>
                    )}
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

export const ChallengeApproval = React.memo(_ChallengeApproval, (prevProps, nextProps) => {
  const isEqual =
    get(prevProps, 'formik.values.phoneCode') === get(nextProps, 'formik.values.phoneCode') &&
    get(prevProps, 'challengeButtonDisabled') === get(nextProps, 'challengeButtonDisabled') &&
    get(prevProps, 'proofButtonDisabled') === get(nextProps, 'proofButtonDisabled') &&
    get(prevProps, 'mobilePhone') === get(nextProps, 'mobilePhone') &&
    get(prevProps, 'challengePayload') === get(nextProps, 'challengePayload');
  return isEqual;
});

_ChallengeApproval.whyDidYouRender = true;
