import * as React from 'react';
import { Button } from '../../../components/Button';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { Divider } from 'antd';
import { ContractT } from '@brainysoft/lk-components';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { useTranslation } from '../../../utils/useTranslation';

interface IProps {
  contract: ContractT;
  dispatch: (action) => void;
  state: any;
}

export const RegularRepaymentAmount: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('RepaymentPage');
  const { t } = useTranslation();
  const formRef = React.useRef<any>();

  const { state } = props;

  const maxAmount = state.data.maxAmount;

  const { dispatch } = props;

  const onSubmit = (values, actions) => {
    if (Number(values.amount) > Number(maxAmount)) {
      actions.setFieldError('amount', 'amount_greater_then_max_value');
    } else {
      dispatch({
        type: 'nextStep',
        payload: {
          amount: values.amount,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <h2 className={classes.header}>{t('repayments:regular.header')}</h2>
        <div className={classes.amountWrapper}>
          <Formik enableReinitialize={true} initialValues={{ amount: 0 }} onSubmit={onSubmit} innerRef={formRef as any}>
            {(formik: FormikProps<any>) => {
              return (
                <FormikForm formik={formik} onSubmit={formik.handleSubmit}>
                  <div className='field'>
                    <MaskedInput
                      name='amount'
                      mask='digits'
                      label={t('repayments:regular.amount')}
                      placeholder={t('repayments:regular.placeholderAmount')}
                      required={true}
                    />
                  </div>
                </FormikForm>
              );
            }}
          </Formik>
        </div>
      </div>

      <Divider />

      <div className={classes.activeCreditButtonWrapper}>
        <Button
          size={'large'}
          type={'primary'}
          onClick={() => {
            formRef.current.submitForm();
          }}
        >
          {t('repayments:regular.pay')}
        </Button>
      </div>
    </React.Fragment>
  );
};
