import React from 'react';
import { FormItem } from '../FormItem';
import { Field } from 'formik';
import { CheckboxProps } from 'antd/lib/checkbox';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { Checkbox as AntdCheckbox } from 'antd';

interface ICheckboxProps extends CheckboxProps {
  className?: string;
  label?: string;
  required?: boolean;
  initialValue?: boolean;
  hidden?: boolean;
}

export const _Checkbox = (props: ICheckboxProps) => {
  const classes = useThemeStyles('Form/CheckBox');

  return (
    <Field name={props.name}>
      {({ form, field, meta }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const onChange = React.useCallback((e) => {
          form.setFieldValue(field.name, e.target.checked);
          form.setFieldTouched(field.name, true);
          if (typeof props.onChange === 'function') props.onChange(e);
        }, []);

        return (
          <FormItem {...props} meta={meta} label={''}>
            <AntdCheckbox
              id={field.name}
              name={field.name}
              checked={!!field.value}
              className={classes.checkbox}
              onChange={onChange}
            >
              <span className={classes.label}>{props.label}</span>
            </AntdCheckbox>
          </FormItem>
        );
      }}
    </Field>
  );
};

export const Checkbox = React.memo(_Checkbox);
